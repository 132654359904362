@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

* {
  margin: 0;
  padding: 0;
}

.App {
  max-width:1200px;
  margin: auto;
  min-height: 100vh;
  position: relative;
}

:root {
  --first-colour: #a7dbd1;
  --second-colour: #337473;
  --third-colour: #ffffff;
  --fourth-colour: black;
}

.logo {
  position: block;
  width: 30vw;
  max-width: 376px;
  padding: 8px;
}

.inactive {
  text-decoration: none;
  color: var(--fourth-colour);
}

.active {
  color: var(--second-colour);
  text-decoration: none;
}

header {
  display: flex;
  justify-content: space-between ;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.5rem;
  font-family: "Noto Sans";
  background-image: url(/src/Assets/paintStripe.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px 24px;
}

@media screen and (min-width: 375px) {
  nav {
  font-size: 0.75rem;
  }
}
@media screen and (min-width: 468px) {
  nav {
  font-size: 1rem;
  }
}
@media screen and (min-width: 568px) {
  nav {
  font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  nav {
  font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  nav {
  font-size: 1.25rem;
  }
}

/* Footer */
.footerAP {
  position: absolute;
  bottom: 0;
  right: 0;
}

.linkedInAP, .gitHubAP, .cvAP {
  width: 5vw;
  margin: 10px;
  }
