.intro {
  display: grid;
  gap: 24px;
  padding: 24px;
}

.intro__image {
  width: 100%;
  object-fit: cover;
  place-self: center;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
}

.intro__text {
  background-image: url(../../Assets/LargePaint.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: column;
  align-self: center;
}

.intro__text__header {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  color: var(--second-colour);
  padding: 5px;
}

.intro__text__copy {
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 1.5rem;
  text-align: right;
  color: var(--fourth-colour);
  padding: 5px;
}

@media screen and (min-width: 375px) {
  .intro {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .intro__text__copy {
    font-size: 1rem;
  }
}
@media screen and (min-width: 468px) {
  .intro__text__copy {
  font-size: 1rem;
  }
}
@media screen and (min-width: 568px) {
  .intro__text__copy {
  font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .intro__text__copy {
  font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .intro__text__copy {
  font-size: 1.5rem;
  }
}