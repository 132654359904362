.contact_wrapper {
  display: grid;
  gap: 24px;
  padding: 30px;
  background-image: url(../../Assets/LargePaint.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact_text {
  margin: auto;
  display: column;
  align-self: center;
}

.contact_header {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  color: var(--second-colour);
  padding: 10px;
}

.contact_content {
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--fourth-colour);
  padding: 10px;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: auto;
}

.contact_name,
.contact_email,
.contact_message {
  width: 250px;
  background: var(--third-colour);
  border-radius: 8px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding: 8px;
  margin: auto;
  border: none;
}

.contact_message {
  height: 150px;
}

.contact_button {
  width: 150px;
  height: 45px;
  border: none;
  background-color: var(--second-colour);
  color: var(--third-colour);
  border-radius: 8px;
  font-size: 20px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  margin: auto;
}

@media screen and (min-width: 375px) {
  .contact_content {
    font-size: 1rem;
  }
}
@media screen and (min-width: 468px) {
  .contact_content {
    font-size: 1rem;
  }
}
@media screen and (min-width: 568px) {
  .contact_content {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .contact_content {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact_content {
    font-size: 1.5rem;
  }
}
